<template>
  <portal to="dialog_top">
    <ws-dialog
        @save="saveVideo"
        :title="$t('ChooseVideo')"
        v-model="display"
        width="750"
        :disable-save="
        (video.is_embed && !video.url) ||
        ( video.is_uploaded && !video.uuid ) ||
        (!video.is_embed && !video.is_uploaded ) ||
        (videoInput && EQ(video,videoInput))
"
    >

      <!-- Video Type Selector  -->
      <v-btn-toggle  @change="changeVideoType" v-model="videoType" mandatory  :color="wsACCENT" background-color="white">
        <v-btn height="40" value="upload" :style="`border-color: ${wsACCENT} !important`" color="white" class="noCaps px-6" >
          <v-icon class="mr-2" text :color="wsACCENT">mdi-upload</v-icon>
          {{  $t('Upload')  }}
        </v-btn>
        <v-btn height="40" value="embed" :style="`border-color: ${wsACCENT} !important`" text class="noCaps px-6" >
          <v-icon class="mr-2" :color="wsACCENT">mdi-link-variant</v-icon>
          {{  $t('EmbedVideo')  }}
        </v-btn>
      </v-btn-toggle>
      <!-- Upload Video Edit  -->

      <wsVideoEditor
          v-if="videoType === 'upload'"
          v-model="video"
          @input="saveUploaded"
          :element-id="elementId"
      />
      <ws-embed-editor
          @input="saveEmbed"
          v-if="videoType === 'embed'"
          v-model="embed"
          video
          no-button
      />



    </ws-dialog>
  </portal>
</template>

<script>


export default {
  name: "imageLibraryDialog",
  props : {
    value : {
      type : Boolean,
      default : false
    },
    elementId : {
      type : Number,
      default : null
    },
    videoInput : {
      type : Object
    }
  },
  data() {
    return {
      display : false,
      videoType : 'upload',
      embed : {},
      video : {}
    }
  },
  watch : {
    display() {
      if ( this.display !== this.value ) {
        this.$emit('input' , this.display)
      }
    },
    value() {
      if ( this.display !== this.value ) {
        this.display = this.value
      }
    }
  },
  methods : {

    saveUploaded() {
      this.video.is_uploaded = true
      this.video.is_embed = false
    },

    saveVideo() {
      this.$emit('select' , this.video)
      this.display = false
    },

    changeVideoType(type) {

      this.video.is_uploaded = type === 'upload'
      this.video.is_embed = type === 'embed'

    },

    async saveEmbed() {

      this.video = {
        is_embed : true,
        url : this.embed.url,
        type : this.embed.type
      }

    },

    parseInputContent() {
      if ( this.videoInput && !this.EQ(this.video , this.videoInput) ) {
        this.video = this.COPY(this.videoInput)
        if ( this.video.is_embed ) {
          this.embed = this.COPY(this.video)
          this.videoType = 'embed'
        }
      }
    }

  },
  mounted() {
    this.display = this.value
    this.parseInputContent()
  }
}
</script>

<style scoped>

</style>