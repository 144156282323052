<template>


  <div>
    <avalon-video-upload-dialog
        v-if="displayLibrary"
        v-model="displayLibrary"
        @select="updateVideo"
        :element-id="element.id"
        :video-input="element.config.video"
    />

    <!--  Content Parameters-->
    <div v-if="!isStyle">


      <v-sheet
          v-if="!element.config.video || (!element.config.video.url && !element.config.video.uuid )"
          @click="openVideoDialog"
          :color="wsLIGHTCARD"
          style="position: relative"
          class="pointer d-flex align-center wsRoundedLight "
          min-height="200"
      >

        <div style="width: 100%">

          <div class="d-flex justify-center">
            <v-btn @click="openVideoDialog" :color="wsATTENTION" icon>
              <v-icon large   :color="wsATTENTION">mdi-plus-circle</v-icon>
            </v-btn>
          </div>

          <h5 v-if="(element.x2- element.x1) > 4 && (element.y2 - element.y1) > 4"
              :style="`color : ${wsDARKLIGHT}`"
              style="max-width: 180px"
              class="mt-2 mx-auto text-center"
          >
            {{ $t('ChooseVideo') }}
          </h5>
        </div>


      </v-sheet>

      <!-- No video Placeholder-->
      <v-sheet
          v-else
          :color="wsLIGHTCARD"
          class="wsRoundedLight "
      >
        <v-sheet
            color="transparent"
            style="pointer-events: none; "
            height="145"
        >
          <ws-video-viewer

              :key="element.config.video.uuid"
              :url="element.config.video.is_embed ? element.config.video.url : null"
              :file="element.config.video.is_uploaded ?  element.config.video : null"
              full-screen
          />
        </v-sheet>

        <div class=" pa-2" style="width: 100%">
          <h5 :style="`color : ${wsACCENT}`"
              style="font-size: 12px"
              class="shorten-text"
          >
            {{ element.config.video.name }}
          </h5>
        </div>

        <div class="d-flex justify-space-between">
          <v-btn @click="deleteVideo" icon :color="wsACCENT">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>

          <ws-button
              @click="openVideoDialog"
              :color="wsACCENT"
              label="Change"
              text
          />

        </div>



      </v-sheet>


    </div>
    <!--  Style Parameters-->
    <div v-else>



    </div>

  </div>

</template>

<script>

import avalonVideoUploadDialog from "@/components/AvalonEditor/Dialogs/avalonVideoUploadDialog";

export default {
  name: "avalonTextSettings",
  components : {
    avalonVideoUploadDialog
  },
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    isStyle : {
      type : Boolean,
      default : false
    },
    element : {
      type : Object,
      default() { return {config : { video : {} }} }
    }
  },
  data() {
    return {
      config : {},
      displayLibrary : false,
    }
  },
  computed : {
    addImageActionsSelect() {
      return [
        { text : this.$t('Upload') , value : 'upload' , icon : 'mdi-upload' },
        { text : this.$t('BrowseCollection') , value : 'browse' , icon : 'mdi-grid' }
      ]
    }
  },
  watch : {
    displayLibrary(value) {
      this.$store.state.avalon.imageLibraryOn = value
    }
  },
  methods : {
    deleteVideo() {
      this.element.config.video = null
      this.element = this.COPY(this.element)
      this.$emit('input' , this.element.config)
    },
    updateVideo(video) {
      this.element.config.video = video
      this.element = this.COPY(this.element)
      this.$emit('input' , this.element.config)
    },
    openVideoDialog() {
      this.displayLibrary = true
    },

    changeMask(mask) {
      this.element.config.mask = mask
      this.
      this.$emit('input' , this.config)
    },
    editLineValign(val) {
      this.notify(val)
    }
  },
  mounted() {
    this.config = this.COPY(this.value)
  }

}
</script>

<style scoped>

</style>